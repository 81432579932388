<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
import Cache from "@/utils/cache";
import {notifySendList, respire} from "@/api/hadmin";

export default {
  data() {
    return {
      timer: null, // 定时器
      isRunning: false, // 是否正在运行
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin;
    }
  },
  watch: {
    // 监听登录状态的变化
    isLogin(newStatus) {
      if (newStatus) {
        this.restartBreathingRequest();
      }
    }
  },
  onLaunch: function (params) {
    this.handleExtraData(params);
    //  清楚缓存
    // #ifndef H5
    this.$log.debug("清楚缓存开始")
    Cache.clearPrefix(process.env.VUE_APP_REQUEST_CACHE_PREFIX);
    this.$log.debug("清楚缓存结束")
    // #endif

    let that = this;
    // #ifdef H5
    uni.getSystemInfo({
      success: function (res) {
        // 首页没有title获取的整个页面的高度，里面的页面有原生标题要减掉就是视口的高度
        // 状态栏是动态的可以拿到 标题栏是固定写死的是44px
        let height = res.windowHeight - res.statusBarHeight - 44
        // #ifdef H5
        that.globalData.windowHeight = res.windowHeight + 'px'
        // #endif
      }
    });
    // #endif
  },
  onShow: function (params) {
    this.handleExtraData(params);
    // #ifdef H5
    uni.getSystemInfo({
      success(e) {
        /* 窗口宽度大于420px且不在PC页面且不在移动设备时跳转至 PC.html 页面 */
        if (e.windowWidth > 420 && !window.top.isPC && !/iOS|Android/i.test(e.system)) {
          /* 若你的项目未设置根目录（默认为 / 时），则使用下方代码 */
          window.location.pathname = '/static/html/pc.html';
        }
      }
    })
    // #endif
    this.isRunning = false;
    this.restartBreathingRequest();
  },
  onHide() {
    this.isRunning = true;
    this.stopBreathingRequest();
  },
  onUnload() {
    this.stopBreathingRequest();
  },
  methods: {
    handleExtraData(options) {
      let extraData = {};
      if (options.referrerInfo && options.referrerInfo.extraData) {
        extraData = options.referrerInfo.extraData;
      }
      this.globalData.extraData = extraData;
    },
    // 启动呼吸请求
    startBreathingRequest() {
      this.$log.debug(this.$options.name, this.timer, this.isLogin, "启动呼吸请求")
      if (this.timer) return;
      this.timer = setInterval(() => {
        this.$log.debug(this.$options.name, this.timer, this.isLogin, "调用呼吸请求")
        if (this.isRunning) {
          this.$log.debug("当前在运行,需等上一个运行结束后继续")
          return;
        }
        // 判断是否登录, 没有登录也不需要继续
        if(!this.isLogin) {
          this.$log.debug("当前还未登录,无需继续")
          return;
        }
        this.isRunning = true;
        const self = this;
        respire().then((result) => {
          self.notifyGlobalWindow(result);
          self.isRunning = false;
          self.$store.dispatch("GLOBAL_DATA", result);
          const num = result.notReadMsgNum || 0;
          self.$util.setTabBarBadge(getCurrentPages(), 2, ['pages/home/index', 'pages/aiOcr/index', "pages/user/index"], num);
        }).catch((code) => {
          this.isRunning = false;
          this.stopBreathingRequest();
        })
      }, 6000);
    },
    // 停止请求
    stopBreathingRequest() {
      this.$log.debug(this.$options.name, "停止呼吸请求")
      clearInterval(this.timer);
      this.timer = null;
    },
    // 重启请求
    restartBreathingRequest() {
      this.$log.debug(this.$options.name, "重启呼吸请求")
      this.stopBreathingRequest();
      this.startBreathingRequest();
    },
    async notifyGlobalWindow(result) {
      if(!result || !result.levelMsgNum) {
        return
      }

      const keyAry = [
        "URGENT",
        "DANGER",
        "ERROR",
      ];

      for (let key of keyAry) {
        if(result.levelMsgNum[key]) {
          // 调用查询列表的 api 获取列表数据展示
          const selectParams = {
            filter: {
              isSee: "FALSE",
              level: key
            },
            pageSize: 5
          };
          // const result = await notifySendList(selectParams, false);
          // if(result) {
          //   // 打开弹框
          // }
          return;
        }
      }
    }
  }
}
</script>

<style lang="scss">
/* 注意要写在第一行，同时给style标签加入lang="scss"属性 */
@import "uview-ui/index.scss";
@import '@/static/css/base.scss';

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'AlibabaPuHuiTiH';  //这个名称可以自己随便定义
  src: url('~@/static/font/dSpPPnpmlofb.woff2') format('woff2');
  font-display: swap;
}

/*使用指定字体*/
* {
  font-family: uicon-iconfont, AlibabaPuHuiTiH;
}
</style>